<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Page Details">
          <validation-observer ref="headerDataValidation">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="page.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Section (A)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.RTIList.A.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.RTIList.A.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (B)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.RTIList.B.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.RTIList.B.description"
                  :options="snowOption"
                />
              </b-col>
              <b-col cols="12">
                <b-form
                  ref="sectionClistForm"
                  class="repeater-form"
                  @submit.prevent="repeatSectionBList(null)"
                >
                  <b-row
                    v-for="(list, index) in page.RTIList.B.list"
                    :id="list.id"
                    :key="list.id"
                    ref="listrow"
                  >
                    <b-col :class="index === 0 ? 'mt-1' : ''" cols="9">
                      <b-form-group label="Title" label-for="food-name">
                        <quill-editor
                          v-model="page.RTIList.B.list[index].title"
                          :options="snowOption"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      :class="index === 0 ? 'mt-1' : ''"
                      md="2"
                      class="align-self-center"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeBListItem(null, index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatSectionBList(null)"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.RTIList.B.list.length === 0
                            ? "Add Lists"
                            : "Add New"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (C)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.RTIList.C.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.RTIList.C.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
          <b-form
            ref="sectionClistForm"
            class="repeater-form"
            @submit.prevent="repeatSectionCList(null)"
          >
            <b-row
              v-for="(list, index) in page.RTIList.C.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="6">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.RTIList.C.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="4">
                <b-form-group label="Document URL" label-for="Document URL">
                  <b-form-input
                    v-model="page.RTIList.C.list[index].DownloadLink"
                    type="text"
                    placeholder="Document URL"
                  />
                </b-form-group>
              </b-col>
              <b-col
                :class="index === 0 ? 'mt-1' : ''"
                md="2"
                class="align-self-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSectionCList(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.RTIList.C.list.length === 0 ? "Add Lists" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Page Details">
          <validation-observer ref="headerDataValidation">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                    >
                      <b-form-input
                        v-model="page.translation.hi.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.translation.hi.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Section (A)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.hi.RTIList.A.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.hi.RTIList.A.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (B)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.hi.RTIList.B.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.hi.RTIList.B.description"
                  :options="snowOption"
                />
              </b-col>
              <b-col cols="12">
                <b-form
                  ref="sectionClistForm"
                  class="repeater-form"
                  @submit.prevent="repeatSectionBList('hi')"
                >
                  <b-row
                    v-for="(list, index) in page.translation.hi.RTIList.B.list"
                    :id="list.id"
                    :key="list.id"
                    ref="listrow"
                  >
                    <b-col :class="index === 0 ? 'mt-1' : ''" cols="9">
                      <b-form-group label="Title" label-for="food-name">
                        <quill-editor
                          v-model="page.translation.hi.RTIList.B.list[index].title"
                          :options="snowOption"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      :class="index === 0 ? 'mt-1' : ''"
                      md="2"
                      class="align-self-center"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeBListItem('hi', index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatSectionBList('hi')"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.translation.hi.RTIList.B.list.length === 0
                            ? "Add Lists"
                            : "Add New"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (C)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.hi.RTIList.C.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.hi.RTIList.C.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
          <b-form
            ref="sectionClistForm"
            class="repeater-form"
            @submit.prevent="repeatSectionCList('hi')"
          >
            <b-row
              v-for="(list, index) in page.translation.hi.RTIList.C.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="6">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translation.hi.RTIList.C.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="4">
                <b-form-group label="Document URL" label-for="Document URL">
                  <b-form-input
                    v-model="page.translation.hi.RTIList.C.list[index].DownloadLink"
                    type="text"
                    placeholder="Document URL"
                  />
                </b-form-group>
              </b-col>
              <b-col
                :class="index === 0 ? 'mt-1' : ''"
                md="2"
                class="align-self-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem('hi',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSectionCList('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.hi.RTIList.C.list.length === 0 ? "Add Lists" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab title="Marathi">
        <b-card title="Page Details">
          <validation-observer ref="headerDataValidation">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                    >
                      <b-form-input
                        v-model="page.translation.mr.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.translation.mr.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Section (A)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.mr.RTIList.A.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.mr.RTIList.A.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (B)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.mr.RTIList.B.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.mr.RTIList.B.description"
                  :options="snowOption"
                />
              </b-col>
              <b-col cols="12">
                <b-form
                  ref="sectionClistForm"
                  class="repeater-form"
                  @submit.prevent="repeatSectionBList('mr')"
                >
                  <b-row
                    v-for="(list, index) in page.translation.mr.RTIList.B.list"
                    :id="list.id"
                    :key="list.id"
                    ref="listrow"
                  >
                    <b-col :class="index === 0 ? 'mt-1' : ''" cols="9">
                      <b-form-group label="Title" label-for="food-name">
                        <quill-editor
                          v-model="page.translation.mr.RTIList.B.list[index].title"
                          :options="snowOption"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      :class="index === 0 ? 'mt-1' : ''"
                      md="2"
                      class="align-self-center"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeBListItem('mr', index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatSectionBList('mr')"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.translation.mr.RTIList.B.list.length === 0
                            ? "Add Lists"
                            : "Add New"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Section (C)">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-form-input
                    v-model="page.translation.mr.RTIList.C.title"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label>Description</label>
                <quill-editor
                  v-model="page.translation.mr.RTIList.C.description"
                  :options="snowOption"
                />
              </b-col>
            </b-row>
          </b-form>
          <b-form
            ref="sectionClistForm"
            class="repeater-form"
            @submit.prevent="repeatSectionCList('mr')"
          >
            <b-row
              v-for="(list, index) in page.translation.mr.RTIList.C.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="6">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translation.mr.RTIList.C.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col :class="index === 0 ? 'mt-1' : ''" cols="4">
                <b-form-group label="Document URL" label-for="Document URL">
                  <b-form-input
                    v-model="page.translation.mr.RTIList.C.list[index].DownloadLink"
                    type="text"
                    placeholder="Document URL"
                  />
                </b-form-group>
              </b-col>
              <b-col
                :class="index === 0 ? 'mt-1' : ''"
                md="2"
                class="align-self-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem('mr',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSectionCList('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.mr.RTIList.C.list.length === 0 ? "Add Lists" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-button @click="savePage" variant="primary" size="lg" block
        >Save Page</b-button
      >
    </b-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import axios from "axios";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BForm,
    BButton,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      page: {
        pageTitle: "",
        description: "",
        RTIList: {
          A: {
            title: "",
            description: "",
          },
          B: {
            title: "",
            description: "",
            list: [],
          },
          C: {
            title: "",
            description: "",
            list: [],
          },
        },
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            RTIList: {
              A: {
                title: "",
                description: "",
              },
              B: {
                title: "",
                description: "",
                list: [],
              },
              C: {
                title: "",
                description: "",
                list: [],
              },
            },
          },
          mr: {
            pageTitle: "",
            description: "",
            RTIList: {
              A: {
                title: "",
                description: "",
              },
              B: {
                title: "",
                description: "",
                list: [],
              },
              C: {
                title: "",
                description: "",
                list: [],
              },
            },
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatSectionCList(lang=undefined) {
      if(lang) {
        this.page.translation[lang].RTIList.C.list.push({
        id: this.page.RTIList.C.list.length + 1,
        title: "",
        DownloadLink: "",
      });
      } else {
        this.page.RTIList.C.list.push({
        id: this.page.RTIList.C.list.length + 1,
        title: "",
        DownloadLink: "",
      });
      }
      
    },
    repeatSectionBList(lang=undefined) {
      if(lang) {
this.page.translation[lang].RTIList.B.list.push({
        id: this.page.RTIList.B.list.length + 1,
        title: "",
      });
      } else {
        this.page.RTIList.B.list.push({
        id: this.page.RTIList.B.list.length + 1,
        title: "",
      });
      }
    },
    removeListItem(lang=undefined, index) {
      if(lang) {
      this.page.translation[lang].RTIList.C.list.splice(index, 1);
      } else {
      this.page.RTIList.C.list.splice(index, 1);
      }
    },
    removeBListItem(lang=undefined, index) {
      if(lang) {
      this.page.translation[lang].RTIList.B.list.splice(index, 1);
      } else {
      this.page.RTIList.B.list.splice(index, 1);
      }
    },
    savePage() {
      this.$refs.headerDataValidation.validate().then((success) => {
        if (success) {
          axios
            .post(`${process.env.VUE_APP_SERVER_URL}/pages/rti/save`, this.page, {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            })
            .then((response) => {
              console.log(response.data);
              this.makeToast("success", "Success", "Page saved successfully!");
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast("danger", "Error", "Couldn't save the data.");
            });
        }
      });
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/rti/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          const { pageTitle, description, RTIList, translation } = response.data;
          console.log(translation)
          const pageData = {
             pageTitle: pageTitle ? pageTitle : "",
            description: description ? description : "",
            RTIList: {
              A: {
                title: RTIList.A.title ? RTIList.A.title : "",
                description: RTIList.A.description ? RTIList.A.description : "",
              },
              B: {
                title: RTIList.B.title ? RTIList.B.title : "",
                description: RTIList.B.description ? RTIList.B.description : "",
                list: RTIList.B.list ? RTIList.B.list : [],
              },
              C: {
                title: RTIList.C.title ? RTIList.C.title : "",
                description: RTIList.C.description ? RTIList.C.description : "",
                list: RTIList.C.list ? RTIList.C.list : [],
              },
            },
            translation: {
              hi: {
               pageTitle: translation && translation.hi.pageTitle ? translation.hi.pageTitle : "",
            description: translation && translation.hi.description ? translation.hi.description : "",
            RTIList: {
              A: {
                title: translation && translation.hi.RTIList &&  translation.hi.RTIList.A && translation.hi.RTIList.A.title ? translation.hi.RTIList.A.title : "",
                description: translation && translation.hi.RTIList &&  translation.hi.RTIList.A && translation.hi.RTIList.A.description ? translation.hi.RTIList.A.description : "",
              },
              B: {
                title: translation && translation.hi.RTIList && translation.hi.RTIList.B && translation.hi.RTIList.B.title ? translation.hi.RTIList.B.title : "",
                description: translation && translation.hi.RTIList && translation.hi.RTIList.B && translation.hi.RTIList.B.description ? translation.hi.RTIList.B.description : "",
                list: translation && translation.hi.RTIList && translation.hi.RTIList.B && translation.hi.RTIList.B.list ? translation.hi.RTIList.B.list : [],
              },
              C: {
                title: translation && translation.hi.RTIList && translation.hi.RTIList.C && translation.hi.RTIList.C.title ? translation.hi.RTIList.C.title : "",
                description: translation && translation.hi.RTIList && translation.hi.RTIList.C && translation.hi.RTIList.C.description ? translation.hi.RTIList.C.description : "",
                list: translation && translation.hi.RTIList && translation.hi.RTIList.C && translation.hi.RTIList.C.list ? translation.hi.RTIList.C.list : [],
              },
            },
              },
              mr: {
                pageTitle: translation && translation.mr.pageTitle ? translation.mr.pageTitle : "",
            description: translation && translation.mr.description ? translation.mr.description : "",
            RTIList: {
              A: {
                title: translation && translation.mr.RTIList && translation.mr.RTIList.A && translation.mr.RTIList.A.title ? translation.mr.RTIList.A.title : "",
                description: translation && translation.mr.RTIList && translation.mr.RTIList.A && translation.mr.RTIList.A.description ? translation.mr.RTIList.A.description : "",
              },
              B: {
                title: translation && translation.mr.RTIList && translation.mr.RTIList.B && translation.mr.RTIList.B.title ? translation.mr.RTIList.B.title : "",
                description: translation && translation.mr.RTIList && translation.mr.RTIList.B && translation.mr.RTIList.B.description ? translation.mr.RTIList.B.description : "",
                list: translation && translation.mr.RTIList && translation.mr.RTIList.B && translation.mr.RTIList.B.list ? translation.mr.RTIList.B.list : [],
              },
              C: {
                title: translation && translation.mr.RTIList && translation.mr.RTIList.C && translation.mr.RTIList.C.title ? translation.mr.RTIList.C.title : "",
                description: translation && translation.mr.RTIList && translation.mr.RTIList.C &&  translation.mr.RTIList.C.description ? translation.mr.RTIList.C.description : "",
                list: translation && translation.mr.RTIList && translation.mr.RTIList.C &&  translation.mr.RTIList.C.list ? translation.mr.RTIList.C.list : [],
              },
            },
              }
            }
          };
          console.log(pageData)
          this.page = pageData;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  created() {
    this.getPage();
  },
};
</script>

<style>
</style>